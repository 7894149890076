import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import { Tooltip } from '@gmini/ui-kit'

import { useAppDispatch, useAppSelector } from '../../store/store'
import { exportUsersXls, getAllUsersPaginated } from '../../store/users/actions'
import { GetUsersPaginatedListParams, User } from '../../store/users/types'
import { AddUserPopup } from '../AddUserPopup/AddUserPopup'
import { EditUserDrawer } from '../EditUserPage/EditUserDrawer'
import { AddUserIcon } from '../icons/AddUserIcon'
import { UsersIcon } from '../icons/UsersIcon'
import { UserList } from '../UserList/UserList'

import {
  AddUserButton,
  ButtonContainer,
  ExportUsersButton,
  ExportUsersButtonWrapper,
  ListHeading,
  StyledCircularProgress,
  Title,
  TitleCount,
  UserListWrapper,
} from './UsersPage.styled'

const size = 100

export const UsersPage = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const [isUnsavedChangesPopupOpen, setIsUnsavedChangesPopupOpen] =
    useState(false)
  const [unsavedUserChanges, setUnsavedUserChanges] = useState(false)
  const [userDetails, setUserDetails] = useState<User | null>(null)
  const [addUserPopupOpen, setAddUserPopupOpen] = useState(false)
  const [exportXlsPending, setExportXlsPending] = useState(false)

  const fetchUserList = useCallback(
    (params: GetUsersPaginatedListParams) =>
      dispatch(getAllUsersPaginated(params)),
    [dispatch],
  )

  const handleExportUsers = async () => {
    setExportXlsPending(true)
    await dispatch(exportUsersXls())
    setExportXlsPending(false)
  }

  useEffect(() => {
    const unblock = history.block(() => {
      if (unsavedUserChanges) {
        setIsUnsavedChangesPopupOpen(true)
        return false
      }
    })
    return unblock
  }, [history, unsavedUserChanges])

  const {
    list: users,
    total,
    totalBySearch,
    pending,
  } = useAppSelector(state => state.allUsersPaginated)

  return (
    <UserListWrapper>
      <ListHeading>
        <Title>
          <UsersIcon />
          <span data-test-id='usersPageHeading'>Пользователи</span>
          <TitleCount data-test-id='usersPageListElementsCount'>
            {totalBySearch !== total ? `${totalBySearch} из ${total}` : total}
          </TitleCount>
        </Title>
        <ButtonContainer>
          <Tooltip title='Выгрузить список пользователей'>
            <ExportUsersButtonWrapper>
              <ExportUsersButton
                data-test-id='exportUsersBtn'
                onClick={() => handleExportUsers()}
                disabled={exportXlsPending}
              />
              {exportXlsPending && <StyledCircularProgress />}
            </ExportUsersButtonWrapper>
          </Tooltip>
          <AddUserButton
            data-test-id='addUserBtn'
            onClick={() => setAddUserPopupOpen(true)}
            size='regular'
            color='primary'
            leftIcon={<AddUserIcon width='16px' height='16px' />}
          >
            <span>Добавить пользователя</span>
          </AddUserButton>
        </ButtonContainer>
      </ListHeading>
      <AddUserPopup
        open={addUserPopupOpen}
        onClose={() => setAddUserPopupOpen(false)}
        withoutRoles
      />
      <EditUserDrawer
        userState={userDetails}
        setUserState={setUserDetails}
        unsavedUserChanges={unsavedUserChanges}
        setUnsavedUserChanges={setUnsavedUserChanges}
        isUnsavedChangesPopupOpen={isUnsavedChangesPopupOpen}
        setIsUnsavedChangesPopupOpen={setIsUnsavedChangesPopupOpen}
      />
      <UserList
        localStoragePrefix='all'
        noExtendedEditOrDelete
        users={users}
        total={totalBySearch}
        size={size}
        fetchUserList={fetchUserList}
        pending={pending}
        setUserDetails={setUserDetails}
        unsavedUserChanges={unsavedUserChanges}
        setIsUnsavedChangesPopupOpen={setIsUnsavedChangesPopupOpen}
      />
    </UserListWrapper>
  )
}
