import { createSlice } from '@reduxjs/toolkit'

import { UserInfo } from '@gmini/common'

import { envName } from '../../config'

import {
  getCurrentUser,
  getProjectUsers,
  getAllUsers,
  getModuleUsers,
  getCompanyUsers,
  getAvailableModules,
  getAllUsersPaginated,
  getUserById,
} from './actions'
import { AvailableModules, User } from './types'

const projectUsersInitialState: {
  list: User[]
  total: number
  totalBySearch: number
  pending: boolean
} = {
  list: [],
  total: 0,
  totalBySearch: 0,
  pending: false,
}

const projectUsers = createSlice({
  name: 'projectUsers',
  initialState: projectUsersInitialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getProjectUsers.fulfilled, (state, { meta, payload }) => ({
        ...state,
        list:
          meta.arg.page === 0
            ? payload?.content
            : [...state.list, ...payload?.content],
        total: payload?.totalElements,
        totalBySearch: payload?.totalElementsBySearch,
        pending: false,
      }))
      .addCase(getProjectUsers.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getProjectUsers.pending, (state, _) => ({
        ...state,
        pending: true,
      }))
      .addCase(getUserById.fulfilled, (state, { payload, meta }) => {
        const targetIdx = state.list.findIndex(user => user.id === payload.id)

        const projectRoles = payload.userRoles.find(
          roles => roles.projectUrn === meta.arg.projectUrn,
        )
        const inProject = Boolean(projectRoles?.roles.length)

        const formattedPayload = {
          ...payload,
          userRoles: projectRoles ? [projectRoles] : [],
        }

        if (!inProject) {
          const newList = state.list.filter(user => user.id !== payload.id)
          return {
            ...state,
            list: newList,
            total: state.total - 1,
          }
        }
        if (targetIdx !== -1) {
          return {
            ...state,
            list: [
              ...state.list.slice(0, targetIdx),
              formattedPayload,
              ...state.list.slice(targetIdx + 1),
            ],
          }
        }

        const newList = state.list
          .concat(formattedPayload)
          .sort((a, b) =>
            (a.lastName || '').localeCompare(b.lastName || '', 'en'),
          )

        return {
          ...state,
          list: newList,
          total: state.total + 1,
        }
      })
  },
})

export const projectUsersReducer = projectUsers.reducer

const companyUsersInitialState: {
  list: User[]
  total: number
  totalBySearch: number
  pending: boolean
} = {
  list: [],
  total: 0,
  totalBySearch: 0,
  pending: false,
}

const companyUsers = createSlice({
  name: 'companyUsers',
  initialState: companyUsersInitialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCompanyUsers.fulfilled, (state, { payload, meta }) => ({
        list:
          meta.arg.page === 0
            ? payload?.content
            : [...state.list, ...payload?.content],
        total: payload?.totalElements,
        totalBySearch: payload?.totalElementsBySearch,
        pending: false,
      }))
      .addCase(getCompanyUsers.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getCompanyUsers.pending, (state, _) => ({
        ...state,
        pending: true,
      }))
      .addCase(getUserById.fulfilled, (state, { payload, meta }) => {
        const inCompany = payload.companyId === Number(meta.arg.companyId)
        const targetIdx = state.list.findIndex(user => user.id === payload.id)

        if (!inCompany) {
          const newList = state.list.filter(user => user.id !== payload.id)
          return {
            ...state,
            list: newList,
            total: state.total - 1,
          }
        }
        if (targetIdx !== -1) {
          return {
            ...state,
            list: [
              ...state.list.slice(0, targetIdx),
              payload,
              ...state.list.slice(targetIdx + 1),
            ],
          }
        }

        const newList = state.list
          .concat(payload)
          .sort((a, b) =>
            (a.lastName || '').localeCompare(b.lastName || '', 'en'),
          )

        return {
          ...state,
          list: newList,
          total: state.total + 1,
        }
      })
  },
})

export const companyUsersReducer = companyUsers.reducer

const moduleUsersInitialState: {
  list: User[]
  total: number
  totalBySearch: number
  pending: boolean
} = {
  list: [],
  total: 0,
  totalBySearch: 0,
  pending: false,
}

const moduleUsers = createSlice({
  name: 'moduleUsers',
  initialState: moduleUsersInitialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getModuleUsers.fulfilled, (state, { payload, meta }) => ({
        ...state,
        list:
          meta.arg.page === 0
            ? payload?.content
            : [...state.list, ...payload?.content],
        total: payload?.totalElements,
        totalBySearch: payload?.totalElementsBySearch,
        pending: false,
      }))
      .addCase(getModuleUsers.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getModuleUsers.pending, (state, _) => ({
        ...state,
        pending: true,
      }))
      .addCase(getUserById.fulfilled, (state, { payload }) => {
        const targetIdx = state.list.findIndex(user => user.id === payload.id)
        if (targetIdx !== -1) {
          return {
            ...state,
            list: [
              ...state.list.slice(0, targetIdx),
              payload,
              ...state.list.slice(targetIdx + 1),
            ],
          }
        }
      })
  },
})

export const moduleUsersReducer = moduleUsers.reducer

const allUsersPaginatedInitialState: {
  list: User[]
  total: number
  totalBySearch: number
  pending: boolean
} = {
  list: [],
  total: 0,
  totalBySearch: 0,
  pending: false,
}

const allUsersPaginated = createSlice({
  name: 'allUsersPaginated',
  initialState: allUsersPaginatedInitialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllUsersPaginated.fulfilled, (state, { payload, meta }) => ({
        ...state,
        list:
          meta.arg.page === 0
            ? payload?.content
            : [...state.list, ...payload?.content],
        total: payload?.totalElements,
        totalBySearch: payload?.totalElementsBySearch,
        pending: false,
      }))
      .addCase(getAllUsersPaginated.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getAllUsersPaginated.pending, (state, _) => ({
        ...state,
        pending: true,
      }))
      .addCase(getUserById.fulfilled, (state, { payload }) => {
        const targetIdx = state.list.findIndex(user => user.id === payload.id)
        if (targetIdx !== -1) {
          return {
            ...state,
            list: [
              ...state.list.slice(0, targetIdx),
              payload,
              ...state.list.slice(targetIdx + 1),
            ],
          }
        }
        const newList = state.list
          .concat(payload)
          .sort((a, b) =>
            (a.lastName || '').localeCompare(b.lastName || '', 'en'),
          )

        return {
          ...state,
          list: newList,
          total: state.total + 1,
        }
      })
  },
})

export const allUsersPaginatedReducer = allUsersPaginated.reducer

const allUsersInitialState: {
  list: User[]
  total: number
  pending: boolean
} = {
  list: [],
  total: 0,
  pending: false,
}

const allUsers = createSlice({
  name: 'allUsers',
  initialState: allUsersInitialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllUsers.fulfilled, (state, { payload }) => ({
        ...state,
        list: payload?.content,
        total: payload.totalElements,
        pending: false,
      }))
      .addCase(getAllUsers.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getAllUsers.pending, (state, _) => ({
        ...state,
        pending: true,
      }))
  },
})

export const allUsersReducer = allUsers.reducer

const currentUserInitialState: UserInfo & {
  loading: boolean
  hubPermissionsMap: { [x: string]: { [x: string]: boolean } }
  availableModules: AvailableModules
  support: boolean
} = {
  id: '',
  name: '',
  email: '',
  hubPermissionsMap: {},
  loading: true,
  availableModules: [],
  support: false,
}

const currentUser = createSlice({
  name: 'currentUser',
  initialState: currentUserInitialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCurrentUser.fulfilled, (state, { payload }) => {
      const hubPermissionsMap = Object.entries(
        payload.hubPermissions?.[`${envName}`] || {},
      ).reduce(
        (acc, [key, scopes]) => ({
          ...acc,
          [key]: scopes.reduce((acc, scope) => ({ ...acc, [scope]: true }), {}),
        }),
        {},
      )
      return {
        ...state,
        ...payload,
        hubPermissionsMap,
        loading: false,
      }
    })
    builder.addCase(getCurrentUser.rejected, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(getCurrentUser.pending, (state, { payload }) => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getAvailableModules.fulfilled, (state, { payload }) => ({
      ...state,
      availableModules: payload,
    }))
  },
})

export const currentUserReducer = currentUser.reducer
