import {
  useEffect,
  useState,
  MouseEvent,
  Dispatch,
  SetStateAction,
} from 'react'
import { useParams, useHistory } from 'react-router'
import { Table, TableColumn } from '@gmini/ui-kit'
import qs from 'query-string'
import { useDebounceState } from '@gmini/utils'

import { useContextMenu } from '@gmini/common'
import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'

import { useAppDispatch } from '../../store/store'

import { User, GetUsersPaginatedListParams } from '../../store/users/types'
import { deleteUserRoles, getUserById } from '../../store/users/actions'

import {
  getColumnsFromStorage,
  setColumnsToStorage,
} from '../../helpers/storageHelper'

import { InfiniteScroll } from '../InfiniteScroll/InfiniteScroll'

import {
  ProjectsColumn,
  ProjectsRolesColumn,
  RowsCountText,
  SearchInputWrapper,
  StyledInput,
  StyledMagnifier,
  TableWrapper,
} from './UserList.styled'
import { ProjectsRolesCell } from './ProjectsRolesCell/ProjectsRolesCell'
import { CompanyCell } from './CompanyCell'

type UserListProps = {
  localStoragePrefix: string
  setUserDetails: Dispatch<SetStateAction<User | null>>
  pending: boolean
  users: User[]
  total: number
  size: number
  fetchUserList: (params: GetUsersPaginatedListParams) => void
  noExtendedEditOrDelete?: boolean
  excludeCompanyField?: boolean
  inProject?: boolean
  excludeProjectRolesField?: boolean
  unsavedUserChanges: boolean
  setIsUnsavedChangesPopupOpen: Dispatch<SetStateAction<boolean>>
}

type UserListRow = User & {
  email?: string
}

const initColumns: TableColumn<UserListRow>[] = [
  {
    field: 'name',
    name: 'Пользователь',
    visible: true,
    cellStyle: { minWidth: '300px', wordBreak: 'break-all' },
    style: { width: 'auto' },
    renderCell: ({ row }) => <>{row.name}</>,
  },
  {
    field: 'email',
    name: 'Email',
    visible: true,
    style: {
      width: '145px',
    },
    renderCell: ({ row }) => <div>{row.email}</div>,
  },
  {
    field: 'phone',
    name: 'Телефон',
    visible: true,
    style: {
      width: '145px',
    },
    renderCell: ({ row }) => <div>{row.phone}</div>,
  },
]

const projectRolesField: (
  inProject: boolean,
) => TableColumn<UserListRow> = inProject => ({
  field: 'userRoles',
  name: inProject ? 'Роль' : 'Проект / Роль',
  visible: true,
  renderCell: ({ row, rowIdx }) => (
    <ProjectsRolesCell
      inProject={inProject}
      userRoles={row.userRoles}
      idx={rowIdx}
    />
  ),
  thContent: (
    <ProjectsRolesColumn>
      {!inProject && <ProjectsColumn>Проект</ProjectsColumn>}
      <div>Роль</div>
    </ProjectsRolesColumn>
  ),
})

const companyNameField: TableColumn<UserListRow> = {
  field: 'companyId',
  name: 'Компания',
  visible: true,
  renderCell: ({ row }) => <CompanyCell id={row.companyId} />,
}

const getInitColumns = (
  initColumns: TableColumn<UserListRow>[],
  params: {
    excludeCompanyField: boolean
    excludeProjectRolesField: boolean
    inProject: boolean
  },
) => {
  const { excludeCompanyField, excludeProjectRolesField, inProject } = params
  let columns = initColumns
  if (!excludeProjectRolesField) {
    columns = [...columns, projectRolesField(inProject)]
  }
  if (!excludeCompanyField) {
    columns = [...columns, companyNameField]
  }
  return columns
}

export const UserList = ({
  setUserDetails,
  pending,
  users,
  total,
  size,
  fetchUserList,
  noExtendedEditOrDelete = false,
  localStoragePrefix,
  excludeProjectRolesField = false,
  excludeCompanyField = false,
  inProject = false,
  setIsUnsavedChangesPopupOpen,
  unsavedUserChanges,
}: UserListProps) => {
  const initSearch = qs.parse(window.location.search).search?.toString() || ''
  const history = useHistory()
  const [page, setPage] = useState(0)
  const params = useParams<{
    hubId: string
    projectUrn: string
    companyId: string
  }>()
  const projectUrn = params.projectUrn || 'default'
  const { companyId } = params
  const { state: inputValue, submit: onInputChange } = useDebounceState<string>(
    {
      onChange: val => {
        const trimmedVal = val.trim()
        const prev = qs.parse(window.location.search)
        const search = qs.stringify(
          { ...prev, search: trimmedVal },
          { skipEmptyString: true },
        )
        history.replace({ search })
      },
      delay: 500,
      initialState: initSearch,
    },
  )

  const dispatch = useAppDispatch()
  const [columns, setColumns] = useState(
    getColumnsFromStorage(
      `${localStoragePrefix}UserListColumnOrder`,
      getInitColumns(initColumns, {
        excludeCompanyField,
        excludeProjectRolesField,
        inProject,
      }),
    ),
  )

  useEffect(() => {
    fetchUserList({
      page: 0,
      size,
      ...(initSearch ? { search: initSearch } : null),
    })
    setPage(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initSearch])

  useEffect(() => {
    if (page === 0) {
      return
    }
    const search = qs.parse(window.location.search).search?.toString() || ''
    fetchUserList({ page, size, ...(search ? { search } : null) })
  }, [page, fetchUserList, size])

  useEffect(() => {
    setColumnsToStorage(`${localStoragePrefix}UserListColumnOrder`, columns)
  }, [columns, localStoragePrefix])

  const { ContextMenu, setCtxMenu, ctxMenu } = useContextMenu<{
    item: User
    event: MouseEvent
  }>([
    {
      title: 'Редактировать',
      dataTestId: 'userListCtxMenuEdit',
      onClick: props => {
        setUserDetails(props.item)
      },
      icon: Icon.EDIT,
    },
    {
      title: 'Архивировать',
      dataTestId: 'userListCtxMenuArchive',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: props => {},
      icon: Icon.ARCHIVE,
      show: () => false,
    },
    {
      title: 'Удалить',
      dataTestId: 'userListCtxMenuDelete',
      onClick: async ({ item: { name, userRoles, id } }) => {
        const roleIds = userRoles
          .find(r => r.projectUrn === projectUrn)
          ?.roles.map(r => r.id)
        if (roleIds) {
          await dispatch(
            deleteUserRoles({
              id,
              name,
              toastrText: 'Пользователь удален',
              roleIds,
              projectUrn,
            }),
          )
          getUserById({ id, projectUrn, companyId })
        }
      },
      icon: Icon.DELETE,
      show: () => !noExtendedEditOrDelete,
    },
  ])

  const listIsNotOver = (total || 0) > (page + 1) * size
  const showedUsersCount = !listIsNotOver ? total : (page + 1) * size
  return (
    <>
      {' '}
      <SearchInputWrapper>
        <StyledMagnifier />
        <StyledInput
          onChange={val => onInputChange(val)}
          value={inputValue}
          clearable
          placeholder='Поиск'
          data-test-id='userListSearchInput'
        />
      </SearchInputWrapper>
      <TableWrapper>
        {!pending && <ContextMenu />}
        <InfiniteScroll
          hasMore={total !== 0 && listIsNotOver}
          next={() => {
            setPage(prev => prev + 1)
          }}
          triggersObserve={[users]}
        >
          <Table
            columns={columns}
            rows={users}
            onRowCtxMenu={(e, item) => {
              e.preventDefault()
              setCtxMenu({
                coords: { x: e.clientX, y: e.clientY },
                item: { item, event: e },
              })
            }}
            pending={pending}
            activeRowKey={ctxMenu.item?.item.id}
            getRowKey={row => row.id}
            onChangeColumns={setColumns}
            onClick={(e, item) => {
              if (unsavedUserChanges) {
                setIsUnsavedChangesPopupOpen(true)
                return
              }
              setUserDetails(item)
            }}
            data-test-id='usersTable'
            trProps={rowKey => ({ 'data-test-id': `usersTableRow_${rowKey}` })}
          />
        </InfiniteScroll>
        <RowsCountText>
          Показаны 1 - {showedUsersCount} из {total}
        </RowsCountText>
      </TableWrapper>
    </>
  )
}
