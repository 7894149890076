import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import { Maintenance, Page404 } from '@gmini/ui-kit'

import { useEffect } from 'react'

import { ProjectPage } from '../components/ProjectPage/ProjectPage'
import { HubPage } from '../components/HubPage/HubPage'
import { AppSidebar } from '../components/AppSidebar/AppSidebar'
import { UsersPage } from '../components/UsersPage/UsersPage'
import { ModulesListPage } from '../components/ModulesListPage/ModulesListPage'

import { Error403Page } from '../components/Error403Page/Error403Page'

import { getError403 } from '../store/403/403-selectors'

import { useAppDispatch, useAppSelector } from '../store/store'
import { getMaintenance } from '../store/maintenance/maintenance-selectors'

import { ModulePage } from '../components/ModulePage/ModulePage'

import { RolesPage } from '../components/RolesPage/RolesPage'

import { CompaniesListPage } from '../components/CompaniesListPage/CompaniesListPage'

import { CompanyPage } from '../components/CompanyPage/CompanyPage'

import {
  ModuleScopes,
  checkProjectsPermissionsSelector,
  checkRolesPermissionsSelector,
} from '../store/users/permissions-selectors'

import { EditProjectPage } from '../components/EditProjectPage/EditProjectPage'

import { AvailableModulesRedirectPage } from '../components/AvailableModulesRedirectPage/AvailableModulesRedirectPage'

import { AppHeader } from '../components/AppHeader/AppHeader'

import { getCurrentUser } from '../store/users/actions'
import { CustomAttributesPage } from '../components/CustomAttributesPage/CustomAttributesPage'

import { AppContentWrapper } from './App.styled'

export const AppContent = () => {
  const { pathname } = useLocation()
  const maintenanceEnabled = useAppSelector(getMaintenance)
  const error403Enabled = useAppSelector(getError403)
  const { loading } = useAppSelector(state => state.currentUser)
  const checkRolesPermissions = useAppSelector(checkRolesPermissionsSelector)
  const checkProjectsPermissions = useAppSelector(
    checkProjectsPermissionsSelector,
  )
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getCurrentUser())
  }, [dispatch])

  if (loading) {
    return null
  }

  if (maintenanceEnabled) {
    return <Maintenance />
  }

  if (error403Enabled) {
    return <Error403Page />
  }

  if (pathname === '/modules') {
    return <AvailableModulesRedirectPage />
  }

  return (
    <>
      <AppHeader />
      <AppContentWrapper>
        <AppSidebar />
        <Switch>
          {checkProjectsPermissions(ModuleScopes.AC_EDIT) ? (
            <Route
              path='/hub/:hubId/project/:projectUrn/edit'
              render={() => <EditProjectPage />}
            />
          ) : null}
          <Route
            path='/hub/:hubId/project/:projectUrn'
            render={() => <ProjectPage />}
          />
          <Route path='/hub/:hubId/users' render={() => <UsersPage />} />
          <Route
            path='/hub/:hubId/modules/:moduleName'
            render={() => <ModulePage />}
          />
          <Route
            path='/hub/:hubId/modules'
            render={() => <ModulesListPage />}
          />
          <Route
            path='/hub/:hubId/companies/:companyId'
            render={() => <CompanyPage />}
          />
          <Route
            path='/hub/:hubId/companies'
            render={() => <CompaniesListPage />}
          />
          <Route
            path='/hub/:hubId/attributes'
            render={() => <CustomAttributesPage />}
          />
          {checkRolesPermissions(ModuleScopes.AC_READ) ? (
            <Route path='/hub/:hubId/roles' render={() => <RolesPage />} />
          ) : null}
          <Route path='/hub/:hubId' exact render={() => <HubPage />} />
          <Route path='/' exact render={() => <Redirect to='/hub/default' />} />
          <Route render={() => <Page404 />} />
        </Switch>
      </AppContentWrapper>
    </>
  )
}
