import { useState } from 'react'
import { AddAfter, IconButton, Popup, RoundedCross } from '@gmini/ui-kit'

import { AttributeGroup, AttributeValue } from '../../store/attributes/types'
import { FolderBigIcon } from '../icons/FolderBigIcon'

import { updateAttributeValue } from '../../store/attributes/actions'

import { useAppDispatch } from '../../store/store'

import {
  Button,
  Container,
  Footer,
  GroupListItem,
  Header,
  PopupContent,
  StyledButton,
  StyledValueName,
  Text,
  Title,
} from './MoveAttributeValueModal.styled'

type MoveAttributeValueModalProps = {
  onClose: () => void
  groupList: AttributeGroup[]
  attributeValueToMove: AttributeValue | null
}

export const MoveAttributeValueModal = ({
  onClose,
  groupList,
  attributeValueToMove,
}: MoveAttributeValueModalProps) => {
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null)
  const dispatch = useAppDispatch()

  const handleSubmit = () => {
    if (!attributeValueToMove || typeof selectedGroupId !== 'number') {
      return
    }
    const {
      id,
      name,
      attributeId,
      parentGroupId: previousGroupId,
    } = attributeValueToMove
    const selectedGroupName = groupList.find(
      g => g.id === selectedGroupId,
    )?.name
    dispatch(
      updateAttributeValue({
        attributeId,
        valueId: id,
        previousGroupId,
        parentGroupId: selectedGroupId,
        notificationText: `Значение атрибута “${name}” перемещено в ${
          selectedGroupName ? `“${selectedGroupName}”` : 'корень списка'
        }`,
        name,
      }),
    )
    handleClose()
  }
  const handleClose = () => {
    setSelectedGroupId(null)
    onClose()
  }
  return (
    <Popup
      width={'600px'}
      open={Boolean(attributeValueToMove)}
      onClose={handleClose}
    >
      <Container>
        <Header>
          <Title data-test-id='moveAttributeValueModalHeading'>
            Переместить значение атрибута
          </Title>
          <IconButton type='square'>
            <RoundedCross
              data-test-id='closeMoveAttributeValueModal'
              onClick={handleClose}
            />
          </IconButton>
        </Header>
        <StyledValueName>{attributeValueToMove?.name}</StyledValueName>
        <Text>Укажите куда необходимо переместить значение атрибута</Text>
        <PopupContent>
          {attributeValueToMove?.parentGroupId ? (
            <GroupListItem
              selected={selectedGroupId === 0}
              onClick={() => setSelectedGroupId(0)}
              key={0}
              data-test-id='moveAttributeValueModalListItem_root'
            >
              <AddAfter />
              <span>В корень списка</span>
            </GroupListItem>
          ) : null}
          {groupList
            .filter(g => g.id !== attributeValueToMove?.parentGroupId)
            .map((g, idx) => (
              <GroupListItem
                data-test-id={`moveAttributeValueModalListItem_${idx}`}
                selected={g.id === selectedGroupId}
                onClick={() => setSelectedGroupId(g.id)}
                key={g.id}
              >
                <FolderBigIcon />
                <span>{g.name}</span>
              </GroupListItem>
            ))}
        </PopupContent>
        <Footer>
          <Button
            data-test-id='moveAttributeValueModalCancelBtn'
            onClick={handleClose}
          >
            Отменить
          </Button>

          <StyledButton
            data-test-id='moveAttributeValueModalConfirmBtn'
            disabled={typeof selectedGroupId !== 'number'}
            onClick={handleSubmit}
          >
            Переместить
          </StyledButton>
        </Footer>
      </Container>
    </Popup>
  )
}
