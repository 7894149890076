export type Attribute = {
  id: number
  name: string
  description: string
  deleted: boolean
}

export type AttributeWithChildren = Attribute & {
  children: (AttributeGroup | AttributeValue)[]
}

export type AttributeGroup = {
  type: AttributeInstances.GROUP
  id: number
  attributeId: number
  name: string
  total: number
}

export type AttributeGroupWithChildren = AttributeGroup & {
  children: AttributeValue[]
}

export type AttributeValue = {
  type: AttributeInstances.VALUE
  id: number
  attributeId: number
  name: string
  parentGroupId: number
}

export enum AttributeInstances {
  ATTRIBUTE = 'Attribute',
  VALUE = 'AttributeValue',
  GROUP = 'AttributeGroup',
}
