import { useEffect, useState, MouseEvent, useMemo } from 'react'
import { useHistory, useParams } from 'react-router'
import { Table, TableColumn } from '@gmini/ui-kit'
import { useContextMenu } from '@gmini/common'
import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'

import { useAppDispatch, useAppSelector } from '../../store/store'
import { Project, mapSourceTypeDescription } from '../../store/projects/types'
import { getProjects } from '../../store/projects/actions'
import { SimpleScrollbar } from '../ProjectList/ProjectList.styled'

import {
  getColumnsFromStorage,
  setColumnsToStorage,
} from '../../helpers/storageHelper'

import {
  ModuleScopes,
  checkProjectsPermissionsSelector,
} from '../../store/users/permissions-selectors'

import {
  SearchInputWrapper,
  StyledInput,
  StyledMagnifier,
} from '../UserList/UserList.styled'

import { TableWrapper } from './ProjectList.styled'

type ProjectListProps = {
  projects: Project[]
  pending: boolean
}

type ProjectListRow = Project & {
  status?: 'Активный' | 'Неактивный'
}

const initColumns: TableColumn<ProjectListRow>[] = [
  {
    field: 'name',
    name: 'Проект',
    visible: true,
    cellStyle: { width: 'auto', maxWidth: '300px' },
    style: { width: 'auto' },
    renderCell: ({ row }) => <>{row.name}</>,
  },
  {
    renderCell: ({ row }) => <>{mapSourceTypeDescription[row.projectSource]}</>,
    name: 'Ресурс',
    field: 'projectSource',
    visible: true,
  },
  // {
  //   field: 'status',
  //   name: 'Статус',
  //   visible: true,
  //   style: {
  //     width: '145px',
  //   },
  //   renderCell: ({ row }) => <div>{row.status || 'Активный'}</div>,
  // },
  {
    field: 'count',
    name: 'Пользователи',
    visible: true,
    renderCell: ({ row }) => <div>{row.count || 0}</div>,
  },
]

export const ProjectList = ({ projects, pending }: ProjectListProps) => {
  const params = useParams<{ hubId: string }>()
  const hubId = params.hubId || 'default'
  const [inputValue, setInputValue] = useState('')
  const [columns, setColumns] = useState(
    getColumnsFromStorage('projectListColumnOrder', initColumns),
  )
  const history = useHistory()

  const dispatch = useAppDispatch()
  const checkProjectsPermissions = useAppSelector(
    checkProjectsPermissionsSelector,
  )

  useEffect(() => {
    dispatch(getProjects())
  }, [dispatch])

  useEffect(() => {
    setColumnsToStorage('projectListColumnOrder', columns)
  }, [columns])

  const { ContextMenu, setCtxMenu, ctxMenu } = useContextMenu<{
    item: Project
    event: MouseEvent
  }>([
    {
      title: 'Перейти',
      dataTestId: 'projectListCtxMenuGo',
      onClick: props => {
        history.push(`/hub/${hubId}/project/${props.item.urn}/`)
      },
      icon: Icon.SEARCH,
    },
    {
      title: 'Редактировать',
      dataTestId: 'projectListCtxMenuEdit',
      onClick: props => {
        history.push(`/hub/${hubId}/project/${props.item.urn}/edit`)
      },
      icon: Icon.EDIT,
      disabled: () => !checkProjectsPermissions(ModuleScopes.AC_EDIT),
    },
    {
      title: 'Архивировать',
      dataTestId: 'projectListCtxMenuArchive',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: props => {},
      icon: Icon.ARCHIVE,
      disabled: () => true,
    },
    {
      title: 'Удалить',
      dataTestId: 'projectListCtxMenuDelete',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: props => {},
      icon: Icon.DELETE,
      disabled: () => true,
    },
  ])

  const filteredProjects = useMemo(
    () =>
      projects.filter(p =>
        (p.searchString || '')
          .toLocaleLowerCase()
          .includes(inputValue.toLocaleLowerCase()),
      ),
    [inputValue, projects],
  )

  return (
    <>
      <SearchInputWrapper>
        <StyledMagnifier />
        <StyledInput
          onChange={val => setInputValue(val)}
          value={inputValue}
          clearable
          placeholder='Поиск'
          data-test-id='companiesListSearchInput'
        />
      </SearchInputWrapper>
      <TableWrapper>
        {!pending && <ContextMenu />}
        <SimpleScrollbar>
          <Table
            columns={columns}
            rows={filteredProjects}
            onRowCtxMenu={(e, item) => {
              e.preventDefault()
              setCtxMenu({
                coords: { x: e.clientX, y: e.clientY },
                item: { item, event: e },
              })
            }}
            pending={pending}
            activeRowKey={ctxMenu.item?.item.urn}
            getRowKey={row => row.urn}
            onChangeColumns={setColumns}
            onClick={(e, item) => {
              history.push(`/hub/${hubId}/project/${item.urn}/`)
            }}
            data-test-id='projectsTable'
            trProps={rowKey => ({
              'data-test-id': `projectsTableRow_${rowKey}`,
            })}
          />
        </SimpleScrollbar>
      </TableWrapper>
    </>
  )
}
