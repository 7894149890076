import React, { SVGAttributes } from 'react'

type SvgComponentType = SVGAttributes<SVGSVGElement> & {
  color?: string
}

export const ArrowExpandMore = ({
  color = '#00003D',
  width = '24',
  height = '24',
  ...other
}: SvgComponentType) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...other}
  >
    <path
      d='M16.1611 9.31092L11.9946 13.4355L7.82819 9.31092C7.62756 9.11187 7.35519 9 7.07114 9C6.7871 9 6.51472 9.11187 6.31409 9.31092C5.8953 9.7255 5.8953 10.3952 6.31409 10.8098L11.243 15.6891C11.6617 16.1036 12.3383 16.1036 12.757 15.6891L17.6859 10.8098C18.1047 10.3952 18.1047 9.7255 17.6859 9.31092C17.2671 8.90697 16.5799 8.89634 16.1611 9.31092Z'
      fill={color}
    />
  </svg>
)
