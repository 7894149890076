import { Header, HeaderLogoWrap, Logo } from '@gmini/ui-kit'
import { getModulesLinkFromEnv } from '@gmini/common'
import { goals } from '@gmini/common/lib/metrika'
import { useNavbar } from '@gmini/common/lib/components'

import { useEffect } from 'react'

import { envLinks } from '../../config'
import { authClient } from '../../keycloak'
import { useAppDispatch, useAppSelector } from '../../store/store'

import { getHubPermissions } from '../../store/roles/actions'

import { BrandContainer, BrandTitle, HeaderContainer } from './AppHeader.styled'

export const AppHeader = () => {
  const userInfo = useAppSelector(state => state.currentUser)
  const { NavbarDropDown } = useNavbar({
    navModules: getModulesLinkFromEnv(envLinks),
  })

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getHubPermissions())
  }, [dispatch])

  return (
    <HeaderContainer>
      <Header
        onLogout={() => authClient.logout()}
        brand={
          <BrandContainer>
            <HeaderLogoWrap>
              <Logo />
            </HeaderLogoWrap>
            <BrandTitle data-test-id='appTitle'>Administration</BrandTitle>
            {NavbarDropDown}
          </BrandContainer>
        }
        userInfo={userInfo}
        onProfileClick={() => goals.showUserMenu()}
      />
    </HeaderContainer>
  )
}
