import { createSlice } from '@reduxjs/toolkit'

import { getAttribute, getAttributeGroup, getAttributes } from './actions'
import { Attribute, AttributeValue, AttributeWithChildren } from './types'

const initialAttributesState: {
  list: Attribute[]
  pending: boolean
  total: number
} = {
  list: [],
  total: 0,
  pending: false,
}

const attributes = createSlice({
  name: 'attributes',
  initialState: initialAttributesState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAttributes.fulfilled, (_, { payload }) => ({
        list: payload,
        total: payload.length || 0,
        pending: false,
      }))
      .addCase(getAttributes.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getAttributes.pending, (state, _) => ({
        ...state,
        pending: true,
      }))
  },
})

export const attributesReducer = attributes.reducer

const initialCurrentAttributeState: AttributeWithChildren & {
  childrenMap: { [x: number]: AttributeValue[] }
  pending: boolean
} = {
  id: 0,
  name: '',
  description: '',
  deleted: true,
  children: [],
  childrenMap: {},
  pending: true,
}

const currentAttribute = createSlice({
  name: 'currentAttribute',
  initialState: initialCurrentAttributeState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAttribute.fulfilled, (state, { payload }) => ({
        ...state,
        ...payload,
        pending: false,
      }))
      .addCase(getAttribute.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getAttribute.pending, (state, _) => ({
        ...state,
        pending: true,
      }))
      .addCase(
        getAttributeGroup.fulfilled,
        (state, { payload: { id, children } }) => ({
          ...state,
          childrenMap: { ...state.childrenMap, [id]: children },
        }),
      )
  },
})

export const currentAttributeReducer = currentAttribute.reducer
