import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { IconButton, PlusCircle } from '@gmini/ui-kit'

import { CtxMenuBaseProps } from '@gmini/common'

import { ArrowExpandMore } from '../icons/ArrowExpandMore'

import {
  AttributeGroup,
  AttributeGroupWithChildren,
  AttributeInstances,
  AttributeValue,
} from '../../store/attributes/types'

import { useAppDispatch, useAppSelector } from '../../store/store'

import {
  addAttributeValue,
  getAttributeGroup,
  updateAttributeGroup,
} from '../../store/attributes/actions'

import {
  ExpandButton,
  GroupName,
  Item,
  ItemSide,
  StyledAttributeInput,
  StyledButton,
  StyledVerticalThreeDots,
} from './AttributeValueGroupItem.styled'
import { AttributeValueItem } from './AttributeValueItem'
import { checkNodeAccordance } from './utils'

type AttributeValueGroupItemProps = {
  group: AttributeGroupWithChildren | AttributeGroup
  dataTestIdPrefix: string
  onActionButtonClick: (
    e: MouseEvent,
    item: AttributeValue | AttributeGroupWithChildren | AttributeGroup,
  ) => void
  ctxMenu: CtxMenuBaseProps<{
    item: AttributeValue | AttributeGroupWithChildren | AttributeGroup
    event: MouseEvent
  }>
  valueToEdit?:
    | AttributeValue
    | AttributeGroupWithChildren
    | AttributeGroup
    | null
  stopEditing?: () => void
}

export const AttributeValueGroupItem = ({
  group,
  onActionButtonClick,
  ctxMenu,
  dataTestIdPrefix,
  valueToEdit = null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  stopEditing = () => {},
}: AttributeValueGroupItemProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const groupInputRef = useRef<HTMLInputElement | null>(null)
  const dispatch = useAppDispatch()
  const { childrenMap } = useAppSelector(state => state.currentAttribute)

  const { name, id, attributeId } = group
  const [expanded, setExpanded] = useState(false)
  const [isAttributeValueInputOpen, setIsAttributeValueInputOpen] =
    useState(false)

  useEffect(() => {
    if (expanded) {
      dispatch(getAttributeGroup({ groupId: id, attributeId }))
    }
  }, [expanded, id, attributeId, dispatch])

  useEffect(() => {
    if (isAttributeValueInputOpen) {
      inputRef.current?.focus()
    }
  }, [isAttributeValueInputOpen])

  useEffect(() => {
    if (checkNodeAccordance(AttributeInstances.GROUP, id, valueToEdit)) {
      groupInputRef.current?.focus()
    }
  }, [valueToEdit, id])

  const handleAddAttributeValue = useCallback(
    (name: string) => {
      dispatch(addAttributeValue({ attributeId, name, parentGroupId: id }))
    },
    [dispatch, attributeId, id],
  )

  const handleEditAttributeGroup = useCallback(
    (name: string) => {
      dispatch(updateAttributeGroup({ attributeId, name, groupId: id }))
    },
    [dispatch, attributeId, id],
  )

  return (
    <>
      {checkNodeAccordance(AttributeInstances.GROUP, id, valueToEdit) ? (
        <StyledAttributeInput
          dataTestIdPrefix={`${dataTestIdPrefix}`}
          inputRef={groupInputRef}
          onClose={stopEditing}
          onSubmit={handleEditAttributeGroup}
          initValue={name}
        />
      ) : (
        <Item data-test-id={`${dataTestIdPrefix}`}>
          <ItemSide>
            <ExpandButton
              data-test-id={`${dataTestIdPrefix}_ExpandBtn`}
              expanded={expanded}
              onClick={() => setExpanded(!expanded)}
            >
              <ArrowExpandMore width={16} height={16} />
            </ExpandButton>
            <GroupName>{name}</GroupName>
          </ItemSide>
          <ItemSide>
            <IconButton
              data-test-id={`${dataTestIdPrefix}_ActionsBtn`}
              onClick={e => onActionButtonClick(e, group)}
              type='circle'
              size='xs'
              active={checkNodeAccordance(
                AttributeInstances.GROUP,
                id,
                ctxMenu.item?.item,
              )}
            >
              <StyledVerticalThreeDots
                active={checkNodeAccordance(
                  AttributeInstances.GROUP,
                  id,
                  ctxMenu.item?.item,
                )}
              />
            </IconButton>
          </ItemSide>
        </Item>
      )}
      {expanded ? (
        <>
          {childrenMap[id]
            ? childrenMap[id].map((node, i) => (
                <AttributeValueItem
                  dataTestIdPrefix={`${dataTestIdPrefix}_${i}`}
                  active={checkNodeAccordance(
                    AttributeInstances.VALUE,
                    node.id,
                    ctxMenu.item?.item,
                  )}
                  onActionButtonClick={onActionButtonClick}
                  key={node.id}
                  value={node}
                  groupId={id}
                  isEditing={checkNodeAccordance(
                    AttributeInstances.VALUE,
                    node.id,
                    valueToEdit,
                  )}
                  stopEditing={stopEditing}
                />
              ))
            : null}
          {!isAttributeValueInputOpen ? (
            <StyledButton
              data-test-id={`${dataTestIdPrefix}_AddNewValueBtn`}
              onClick={() => setIsAttributeValueInputOpen(true)}
              color='tertiary'
              leftIcon={<PlusCircle />}
            >
              Добавить значение
            </StyledButton>
          ) : (
            <StyledAttributeInput
              dataTestIdPrefix={`${dataTestIdPrefix}_AddNewValue`}
              isAttributeValue
              groupId={id}
              inputRef={inputRef}
              onClose={() => setIsAttributeValueInputOpen(false)}
              onSubmit={handleAddAttributeValue}
            />
          )}
        </>
      ) : null}
    </>
  )
}
