export type ProjectDto = {
  objects: null
  project: {
    name: string
    projectSource: 'Bim360' | 'GStation'
    urn: string
    archived: boolean
  }
}

export type Project = ProjectDto['project'] & {
  count?: number
  searchString?: string
}

export const mapSourceTypeDescription: Record<
  Project['projectSource'],
  string
> = {
  Bim360: 'BIM360',
  GStation: 'G-station',
}
