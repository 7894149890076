import { IconButton } from '@gmini/ui-kit'

import { MouseEvent, useCallback, useEffect, useRef } from 'react'

import { AttributeGroup, AttributeValue } from '../../store/attributes/types'

import { useAppDispatch } from '../../store/store'
import { updateAttributeValue } from '../../store/attributes/actions'

import {
  ItemSide,
  ValueName,
  Item,
  NameText,
  StyledVerticalThreeDots,
  StyledAttributeInput,
} from './AttributeValueGroupItem.styled'

type AttributeValueItemProps = {
  value: AttributeValue
  groupId?: number
  active?: boolean
  onActionButtonClick: (
    e: MouseEvent,
    item: AttributeValue | AttributeGroup,
  ) => void
  isEditing?: boolean
  stopEditing?: () => void
  dataTestIdPrefix: string
}

export const AttributeValueItem = ({
  value,
  dataTestIdPrefix,
  groupId,
  onActionButtonClick,
  active,
  isEditing = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  stopEditing = () => {},
}: AttributeValueItemProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const dispatch = useAppDispatch()
  const { attributeId, id: valueId, parentGroupId, name } = value

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus()
    }
  }, [isEditing])

  const handleEditAttributeValue = useCallback(
    (name: string) => {
      dispatch(
        updateAttributeValue({ attributeId, name, parentGroupId, valueId }),
      )
    },
    [attributeId, dispatch, parentGroupId, valueId],
  )

  return !isEditing ? (
    <Item data-test-id={`${dataTestIdPrefix}`} nested={Boolean(groupId)}>
      <ItemSide>
        <ValueName>
          <NameText>{name}</NameText>
        </ValueName>
      </ItemSide>
      <ItemSide>
        <IconButton
          data-test-id={`${dataTestIdPrefix}_ActionsBtn`}
          onClick={e => onActionButtonClick(e, value)}
          type='circle'
          size='xs'
          active={active}
        >
          <StyledVerticalThreeDots active={active} />
        </IconButton>
      </ItemSide>
    </Item>
  ) : (
    <StyledAttributeInput
      dataTestIdPrefix={`${dataTestIdPrefix}`}
      groupId={groupId}
      isAttributeValue
      inputRef={inputRef}
      onClose={stopEditing}
      onSubmit={handleEditAttributeValue}
      initValue={name}
    />
  )
}
