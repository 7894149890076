import { createAsyncThunk } from '@reduxjs/toolkit'
import get from 'lodash/get'
import { toastr } from 'react-redux-toastr'

import { apiClient } from '../../services/api'

import { SnackbarWrapper } from '../../components/toastrOptions/ToastrSnackbar.styled'

import { modulesNames } from '../modules/types'

import { downloadFile } from '../utils'

import { UpdateHubPermissionsBody } from '../roles/types'

import {
  AvailableModules,
  GetUsersPaginatedListParams,
  GetUsersPaginatedListResult,
  User,
  UserWithHubPermissions,
} from './types'

export const getUserById = createAsyncThunk<
  User,
  { id: string; projectUrn?: string; companyId?: string }
>('users/getUserById', async ({ id }, { rejectWithValue }) => {
  const [err, res] = await apiClient.get<User>({
    path: `/auth/users/${id}/full`,
  })

  if (err) {
    throw rejectWithValue(err)
  }

  const data: User = get(res, 'data.data', {
    id: '',
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    permissions: [],
    hubPermissions: {},
    userRoles: [],
    companyId: null,
    support: false,
    adminPanelUser: false,
  })

  return data
})

export const createUser = createAsyncThunk<
  User,
  {
    firstName: string
    lastName: string
    email: string
    phone: string
    companyId: number | null
    notify?: boolean
  }
>(
  'users/createUser',
  async (
    { firstName, lastName, email, phone, companyId, notify },
    { rejectWithValue },
  ) => {
    const [err, res] = await apiClient.post<
      {
        firstName: string
        lastName: string
        email: string
        phone: string
        companyId: number | null
      },
      User
    >({
      path: '/auth/admin/users',
      body: {
        firstName,
        lastName,
        email,
        phone,
        companyId,
      },
    })

    if (err) {
      throw rejectWithValue(err)
    }

    const data: User = get(res, 'data.data', {
      name: '',
      firstName: '',
      lastName: '',
      phone: '',
      id: '',
      userRoles: [],
      hubPermissions: {},
      email: '',
      companyId: null,
      support: false,
      adminPanelUser: false,
    })

    if (notify) {
      toastr.info('', '', {
        icon: <div />,
        component: (
          <SnackbarWrapper>
            Пользователь создан: <span>{data.name}</span>
          </SnackbarWrapper>
        ),
      })
    }

    return data
  },
)

export const updateUser = createAsyncThunk<
  void,
  {
    id: string
    phone: string
    email: string
    companyId: number | null
    firstName: string
    lastName: string
  }
>(
  'users/updateUser',
  async (
    { id, phone, email, companyId, firstName, lastName },
    { rejectWithValue },
  ) => {
    const [err] = await apiClient.put<
      {
        phone: string
        companyId: number | null
        firstName: string
        lastName: string
      },
      User
    >({
      path: `/auth/admin/users/${id}`,
      body: {
        phone,
        companyId,
        firstName,
        lastName,
      },
    })

    if (err) {
      throw rejectWithValue(err)
    }

    toastr.info('', '', {
      icon: <div />,
      component: (
        <SnackbarWrapper>
          Пользователь изменен: <span>{`${lastName} ${firstName}`}</span>
        </SnackbarWrapper>
      ),
    })
  },
)

export const updateUserHubPermissions = createAsyncThunk<
  Omit<User, 'userRoles'>,
  {
    userId: string
    hubId: string
    hubPermissions: UpdateHubPermissionsBody
    name: string
  }
>(
  'users/updateUserHubPermissions',
  async ({ userId, hubPermissions, hubId, name }, { rejectWithValue }) => {
    const [err, res] = await apiClient.put<
      UpdateHubPermissionsBody,
      Omit<User, 'userRoles'>
    >({
      path: `/auth/admin/hub-permissions/hub/${hubId}/user/${userId}`,
      body: hubPermissions,
    })

    if (err) {
      throw rejectWithValue(err)
    }

    toastr.info('', '', {
      icon: <div />,
      component: (
        <SnackbarWrapper>
          Доступы пользователя изменены: <span>{name}</span>
        </SnackbarWrapper>
      ),
    })
    const data: Omit<User, 'userRoles'> = get(res, 'data.data', {
      name: '',
      firstName: '',
      lastName: '',
      phone: '',
      id: '',
      hubPermissions: {},
      email: '',
      companyId: null,
      support: false,
      adminPanelUser: false,
    })

    return data
  },
)

export const addUserRoles = createAsyncThunk<
  User,
  {
    id: string
    projectUrn: string
    roleIds: number[]
    toastrText?: string
    name: string
  }
>(
  'users/addUserRoles',
  async (
    { id, roleIds, projectUrn, toastrText, name },
    { rejectWithValue },
  ) => {
    const [err, res] = await apiClient.post<
      {
        roleIds: number[]
      },
      User
    >({
      path: `/auth/admin/users/${id}/project/${projectUrn}/add-roles`,
      body: {
        roleIds,
      },
    })

    if (err) {
      throw rejectWithValue(err)
    }

    if (toastrText) {
      toastr.info('', '', {
        icon: <div />,
        component: (
          <SnackbarWrapper>
            {toastrText}: <span>{name}</span>
          </SnackbarWrapper>
        ),
      })
    }
    const data: User = get(res, 'data.data', {
      name: '',
      firstName: '',
      lastName: '',
      phone: '',
      id: '',
      userRoles: [],
      hubPermissions: {},
      email: '',
      companyId: null,
      support: false,
      adminPanelUser: false,
    })

    return data
  },
)

export const deleteUserRoles = createAsyncThunk<
  User,
  {
    id: string
    projectUrn: string
    roleIds: number[]
    name: string
    toastrText: string
  }
>(
  'users/deleteUserRoles',
  async (
    { id, projectUrn, roleIds, name, toastrText },
    { rejectWithValue },
  ) => {
    const [err, res] = await apiClient.post<{ roleIds: number[] }, User>({
      path: `/auth/admin/users/${id}/project/${projectUrn}/remove-roles`,
      body: {
        roleIds,
      },
    })

    if (err) {
      throw rejectWithValue(err)
    }

    toastr.info('', '', {
      icon: <div />,
      component: (
        <SnackbarWrapper>
          {toastrText}: <span>{name}</span>
        </SnackbarWrapper>
      ),
    })

    const data: User = get(res, 'data.data', {
      name: '',
      firstName: '',
      lastName: '',
      phone: '',
      id: '',
      userRoles: [],
      hubPermissions: {},
      email: '',
      companyId: null,
      support: false,
      adminPanelUser: false,
    })

    return data
  },
)

export const getCompanyUsers = createAsyncThunk<
  GetUsersPaginatedListResult,
  GetUsersPaginatedListParams & { companyId: string }
>(
  'users/getCompanyUsers',
  async ({ companyId, ...restParams }, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<GetUsersPaginatedListResult>({
      path: `/auth/admin/users`,
      params: {
        'company-id': companyId,
        'with-project-name': true,
        ...restParams,
      },
    })

    if (err) {
      throw rejectWithValue(err)
    }

    const data: GetUsersPaginatedListResult = get(res, 'data.data', {
      content: [],
      totalElements: 0,
      totalElementsBySearch: 0,
    })

    return data
  },
)

export const getProjectUsers = createAsyncThunk<
  GetUsersPaginatedListResult,
  GetUsersPaginatedListParams & { projectUrn: string }
>(
  'users/getProjectUsers',
  async ({ projectUrn, ...restParams }, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<GetUsersPaginatedListResult>({
      path: `/auth/admin/users`,
      params: {
        'project-urn': projectUrn,
        'with-project-name': true,
        ...restParams,
      },
    })

    if (err) {
      throw rejectWithValue(err)
    }

    const data: GetUsersPaginatedListResult = get(res, 'data.data', {
      content: [],
      totalElements: 0,
      totalElementsBySearch: 0,
    })

    return data
  },
)

export const getModuleUsers = createAsyncThunk<
  GetUsersPaginatedListResult,
  GetUsersPaginatedListParams & { moduleName: keyof typeof modulesNames }
>(
  'users/getModuleUsers',
  async ({ moduleName, ...restParams }, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<GetUsersPaginatedListResult>({
      path: `/auth/admin/users`,
      params: {
        'module-name': moduleName,
        'with-project-name': true,
        ...restParams,
      },
    })

    if (err) {
      throw rejectWithValue(err)
    }

    const data: GetUsersPaginatedListResult = get(res, 'data.data', {
      content: [],
      totalElements: 0,
      totalElementsBySearch: 0,
    })

    return data
  },
)

export const getAllUsersPaginated = createAsyncThunk<
  GetUsersPaginatedListResult,
  GetUsersPaginatedListParams
>('users/getAllUsersPaginated', async (params, { rejectWithValue }) => {
  const [err, res] = await apiClient.get<GetUsersPaginatedListResult>({
    path: '/auth/admin/users',
    params: {
      'with-project-name': true,
      ...params,
    },
  })

  if (err) {
    throw rejectWithValue(err)
  }

  const data: GetUsersPaginatedListResult = get(res, 'data.data', {
    content: [],
    totalElements: 0,
    totalElementsBySearch: 0,
  })

  return data
})

export const getAllUsers = createAsyncThunk<
  GetUsersPaginatedListResult,
  GetUsersPaginatedListParams
>('users/getAllUsers', async (params, { rejectWithValue }) => {
  const [err, res] = await apiClient.get<GetUsersPaginatedListResult>({
    path: '/auth/admin/users',
    params: {
      'with-project-name': true,
      ...params,
    },
  })

  if (err) {
    throw rejectWithValue(err)
  }

  const data: GetUsersPaginatedListResult = get(res, 'data.data', {
    content: [],
    totalElements: 0,
    totalElementsBySearch: 0,
  })

  return data
})

export const getAvailableModules = createAsyncThunk<AvailableModules, void>(
  'users/getAvailableModules',
  async (_, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<AvailableModules>({
      path: '/auth/users/modules-with-hosts',
    })

    if (err) {
      throw rejectWithValue(err)
    }

    const data: AvailableModules = get(res, 'data.data', [])

    return data
  },
)

export const getCurrentUser = createAsyncThunk<UserWithHubPermissions, void>(
  'users/getCurrentUser',
  async (_, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<UserWithHubPermissions>({
      path: '/auth/current-user',
      params: {
        'retrieve-permissions': true,
      },
    })

    if (err) {
      throw rejectWithValue(err)
    }

    const data: UserWithHubPermissions = get(res, 'data.data', {
      id: '',
      name: '',
      email: '',
      hubPermissions: {},
    })

    return data
  },
)

export const getProjectsUsersCount = createAsyncThunk<
  { projectUrn: string; count: number }[],
  void
>('projects/getProjectsUsersCount', async (_, { rejectWithValue }) => {
  const [err, res] = await apiClient.get({
    path: '/auth/admin/users/count',
  })
  if (err) {
    throw rejectWithValue(err)
  }

  const data: { projectUrn: string; count: number }[] = get(
    res,
    'data.data',
    [],
  )

  return data
})

export const getCompaniesUsersCount = createAsyncThunk<
  { companyId: string; count: number }[],
  void
>('projects/getCompaniesUsersCount', async (_, { rejectWithValue }) => {
  const [err, res] = await apiClient.get({
    path: '/auth/admin/users/count-by-companies',
  })
  if (err) {
    throw rejectWithValue(err)
  }

  const data: { companyId: string; count: number }[] = get(res, 'data.data', [])

  return data
})

export const exportUsersXls = createAsyncThunk<void, void>(
  'projects/exportUsersXls',
  async (_, { rejectWithValue }) => {
    const [err, blob] = await apiClient.get<Blob>({
      path: '/auth/admin/users/export',
      responseType: 'blob',
    })
    if (err) {
      throw rejectWithValue(err)
    }
    downloadFile(blob)
  },
)
