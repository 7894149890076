import { useContextMenu } from '@gmini/common'
import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'
import { HorizontalThreeDots } from '@gmini/ui-kit'
import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'

import {
  deleteRole,
  getResources,
  getRoles,
  updateRole,
} from '../../store/roles/actions'

import { Role, Module, Scope } from '../../store/roles/types'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { UserType } from '../../store/users/types'
import { ConfirmChangesOverlay } from '../ConfirmChangesOverlay/ConfirmChangesOverlay'
import { PermissionSetting } from '../PermissionSetting/PermissionSetting'
import { RoleBadge } from '../RoleBadge/RoleBadge'
import { RoleChangesPopup } from '../RoleChangesPopup/RoleChangesPopup'

import {
  ModuleScopes,
  checkRolesPermissionsSelector,
} from '../../store/users/permissions-selectors'

import {
  ChangesPopupContent,
  Description,
  Heading,
  Hint,
  PermissionSettingsBlock,
  PermissionsList,
  RoleProfileWrapper,
  StyledAccordion,
  StyledScrollbar,
  Title,
  TitleButton,
  TitleContainer,
} from './RoleProfile.styled'

type RoleProfileProps = {
  role: Role
  setSelectedRole: Dispatch<SetStateAction<Role | null>>
  changesMap: { [x: string]: number }
  setChangesMap: Dispatch<SetStateAction<{ [x: string]: number }>>
  saveChangesPopupOpen: boolean
  setSaveChangesPopupOpen: Dispatch<SetStateAction<boolean>>
}

const getTooltipTitle = (accessDenied: boolean, system: boolean) => {
  if (system) {
    return 'Системные роли нельзя редактировать'
  }
  if (accessDenied) {
    return 'У вас нет прав на редактирование ролей'
  }
  return ''
}

export const RoleProfile = ({
  role,
  setSelectedRole,
  changesMap,
  setChangesMap,
  saveChangesPopupOpen,
  setSaveChangesPopupOpen,
}: RoleProfileProps) => {
  const { list: resources } = useAppSelector(state => state.resources)
  const checkRolesPermissions = useAppSelector(checkRolesPermissionsSelector)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getResources())
  }, [dispatch])

  const { ContextMenu, setCtxMenu, ctxMenu } = useContextMenu<{
    item: Role
    event: MouseEvent
  }>([
    {
      title: 'Клонировать роль',
      dataTestId: 'roleProfileCtxMenuClone',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: props => {},
      icon: Icon.COPY,
      disabled: () => true,
    },
    {
      dataTestId: 'roleProfileCtxMenuDelete',
      title: 'Удалить',
      onClick: async ({ item: { id } }) => {
        await dispatch(
          deleteRole({
            id,
          }),
        )
        setSelectedRole(null)
        dispatch(getRoles({ projectUrn: '' }))
      },
      icon: Icon.DELETE,
      disabled: () => true,
    },
  ])

  const { id, title, description, resources: permissionsMap } = role

  const [incomingPermissions, setIncomingPermissions] = useState(permissionsMap)

  useEffect(() => {
    setIncomingPermissions(role.resources)
  }, [role])

  const handlePermissionChange =
    (module: Module, resourceId: string) => (scopes: Scope[]) => {
      const newIncomingPermissions = {
        ...incomingPermissions,
        [module.moduleId]: {
          ...module,
          resources: {
            ...module.resources.reduce(
              (acc, r) => ({
                ...acc,
                [r.resourceId]:
                  incomingPermissions?.[module.moduleId]?.resources[
                    r.resourceId
                  ],
              }),
              {},
            ),
            [resourceId]: {
              ...module.resources.find(r => r.resourceId === resourceId),
              scopes,
            },
          },
        },
      }
      setIncomingPermissions(newIncomingPermissions)
    }

  const handleSubmit = useCallback(async () => {
    const newPermissionsMap = {
      ...Object.entries(incomingPermissions || {}).reduce(
        (acc, [key, m]) => ({
          ...acc,
          [key]: {
            ...Object.entries(m.resources).reduce(
              (acc, [rkey, r]) => ({
                ...acc,
                [rkey]: r?.scopes.map(s => s.scopeId),
              }),
              {},
            ),
          },
        }),
        {},
      ),
    }
    const newRole = await dispatch(
      updateRole({
        id,
        title,
        description,
        permissions: newPermissionsMap,
      }),
    ).unwrap()
    setSelectedRole(newRole)
    setChangesMap({})
    await dispatch(getRoles({ projectUrn: '' }))
  }, [
    description,
    dispatch,
    id,
    incomingPermissions,
    setSelectedRole,
    title,
    setChangesMap,
  ])

  const changesCount = Object.values(changesMap).reduce((acc, c) => acc + c, 0)

  const roleEditAllowed = checkRolesPermissions(ModuleScopes.AC_EDIT)

  return (
    <RoleProfileWrapper visible={Boolean(title)}>
      <ContextMenu />
      <TitleContainer>
        <Title data-test-id='roleProfileHeading'>{title}</Title>
        <TitleButton
          data-test-id='roleProfileActionsBtn'
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            setCtxMenu({
              coords: { x: e.clientX, y: e.clientY },
              item: { item: role, event: e },
            })
          }}
          active={Boolean(ctxMenu.item?.item.id)}
          size='small'
        >
          <HorizontalThreeDots />
        </TitleButton>
      </TitleContainer>
      {description ? <Description>{description}</Description> : null}
      <Hint>Доступ к модулям</Hint>
      <StyledScrollbar>
        <PermissionsList>
          {resources.map((m, moduleIdx) => {
            const scopesLength = Object.values(
              permissionsMap?.[m.moduleId]?.resources || {},
            ).flatMap(r => r.scopes).length
            const allScopesLength = m.resources.flatMap(r => r.scopes).length
            return allScopesLength ? (
              <StyledAccordion
                data-test-id={`roleProfileModule_${moduleIdx}`}
                key={m.id}
                title={m.name}
                grayscaled={!scopesLength}
                badge={
                  scopesLength === allScopesLength ? (
                    <RoleBadge
                      noMargin
                      roleId={UserType.NEW}
                      roleTitle='Разрешено все'
                    />
                  ) : null
                }
              >
                <>
                  <Heading>
                    <div>Наименование</div>
                    <div>Разрешение</div>
                  </Heading>
                  <PermissionSettingsBlock grayscaled={!scopesLength}>
                    {m.resources.map((r, idx) => (
                      <PermissionSetting
                        dataTestIdPrefix={`roleProfileModule_${moduleIdx}_resource_${idx}`}
                        key={r.id}
                        tooltipTitle={getTooltipTitle(
                          !roleEditAllowed,
                          role.system,
                        )}
                        handleUpdateRole={handlePermissionChange(
                          m,
                          r.resourceId,
                        )}
                        setChangesMap={setChangesMap}
                        disabled={!roleEditAllowed || role.system}
                        resource={{
                          id: r.id,
                          name: r.name,
                          curScopes:
                            incomingPermissions?.[m.moduleId]?.resources[
                              r.resourceId
                            ]?.scopes || [],
                          initScopes:
                            permissionsMap?.[m.moduleId]?.resources[
                              r.resourceId
                            ]?.scopes || [],
                        }}
                        options={r.scopes}
                      />
                    ))}
                  </PermissionSettingsBlock>
                </>
              </StyledAccordion>
            ) : null
          })}
        </PermissionsList>
      </StyledScrollbar>
      <ConfirmChangesOverlay
        changesCount={changesCount}
        onSubmit={() => handleSubmit()}
        onReset={() => {
          setIncomingPermissions(permissionsMap)
          setChangesMap({})
        }}
      />
      <RoleChangesPopup
        title='Изменения не сохранены'
        submitButtonTitle='Сохранить изменения'
        open={saveChangesPopupOpen}
        width='600px'
        onClose={() => {
          setSaveChangesPopupOpen(false)
        }}
        onSubmit={() => handleSubmit()}
        onReset={() => {
          setIncomingPermissions(permissionsMap)
          setChangesMap({})
        }}
      >
        {() => (
          <ChangesPopupContent>
            Внесенные изменения в роль <span>“{role.title}”</span> не сохранены.
            Выберите требуемое действие.
          </ChangesPopupContent>
        )}
      </RoleChangesPopup>
    </RoleProfileWrapper>
  )
}
