import { Button, VerticalThreeDots } from '@gmini/ui-kit'
import styled, { css } from 'styled-components'

import { AttributeInput } from '../AttributeInput/AttributeInput'

type ExpandButtonProps = {
  expanded: boolean
}

export const ExpandButton = styled.div`
  display: flex;
  padding: 4px;
  transition: all ease-in-out 0.2s;
  &:hover {
    cursor: pointer;
    & path {
      fill: rgba(0, 0, 61, 1);
    }
  }
  ${({ expanded }: ExpandButtonProps) =>
    !expanded
      ? css`
          transform: rotate(-90deg);
        `
      : ''}
`

export const TextStyles = css`
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  color: #00003d;
`

export const NestedStyles = css`
  position: relative;
  margin-left: 29px;
`

export const GroupName = styled.div`
  ${TextStyles}
`

export const ValueName = styled.div`
  ${TextStyles}
  background: #ebebef;
  padding: 3.5px 8px;
  border-radius: 4px;
  width: 100%;
`

export const ItemSide = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  max-width: 91%;
`

type ItemProps = {
  nested?: boolean
}

type StyledVerticalThreeDotsProps = {
  active?: boolean
}

export const StyledVerticalThreeDots = styled(VerticalThreeDots)`
  opacity: 0;
  padding: 3px;
  margin: 2px;
  ${({ active }: StyledVerticalThreeDotsProps) => (active ? 'opacity: 1;' : '')}
`

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 12px 3px 5px;
  border-radius: 4px;
  ${({ nested }: ItemProps) =>
    nested
      ? css`
          ${NestedStyles}
        `
      : ''}

  &:hover {
    background: rgba(76, 94, 207, 0.1);
    ${StyledVerticalThreeDots} {
      opacity: 1;
    }
  }
`

export const NameText = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`

export const StyledButton = styled(Button)`
  ${NestedStyles}
`

type StyledAttributeInputProps = {
  groupId?: number
}

export const StyledAttributeInput = styled(AttributeInput)`
  ${({ groupId }: StyledAttributeInputProps) =>
    groupId
      ? css`
          ${NestedStyles}
        `
      : ''}
`
